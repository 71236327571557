import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.modal.raw');
import { OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-modal-raw',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/component.modal.raw/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
}
.wiz-modal .modal-content {
  padding: 20px;
  width: 600px;
  max-height: 955px;
  overflow-y: auto;
  background-color: #EAF0FB;
}
.wiz-modal .modal-content .title {
  font-family: SUIT;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content .table-responsive {
  background: #EAF0FB;
  border-radius: 20px;
  max-height: 800px;
  padding: 20px;
}
.wiz-modal .modal-content a {
  cursor: pointer;
  color: black;
  font-size: 16px;
}
.wiz-modal .modal-content a:hover {
  font-weight: bold;
  color: #6758F0;
}

.btn-hide {
  background: white;
  color: #6758F0;
  border: 1px solid #6758F0;
}

.btn-download {
  background: #6758F0;
  color: white;
  border: 1px solid #6758F0;
}

.box-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}`],
})
export class ComponentModalRawComponent implements OnInit {
    public isshow: boolean = true;
    public rawdata: any = {};
    public user_name: any;
    public user_email: any;

    @Input() datacode: any;
    @Input() year: any;
    @Output() modalHide = new EventEmitter<boolean>();

    constructor(@Inject( Service) public service: Service) {
    }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        this.isshow = true;
        await this.service.render();
    }

    public async hide() {
        this.modalHide.emit(false);
        await this.service.render();
    }

    public async load() {
        const { code, data } = await wiz.call("load", { datacode: this.datacode });
        if (code != 200) loacation.href = "/";
        this.rawdata = data;
        await this.service.render;
    }

    public async download() {
        if (!this.user_name || this.user_name == "") return await this.alert("신청자 명을 입력해주세요.", 'error');
        if (!this.user_email || this.user_email == "") return await this.alert("신청자 이메일을 입력해주세요.", 'error');
        let text = "정말 " + this.year + "년도 " + this.rawdata.title + " 데이터셋을 다운로드하시겠습니까?";
        let res = await this.alert(text, "success", "취소");
        if (!res) return;

        await this.loading(true);
        const { code } = await wiz.call("download", {datacode:this.datacode, device:this.rawdata.code, year:this.year, name:this.user_name, email:this.user_email});
        await this.loading(false);
        if (code!=200) return await this.alert("다운로드에 실패하였습니다.", "error")
        await this.alert("다운로드 및 암호는 Raw 데이터 공유 로그 페이지에서 확인하실 수 있습니다.", "success");
        await this.hide();
        location.href = "share/raw";
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }

    public async alert(message: string, status: string = 'success', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
}

export default ComponentModalRawComponent;