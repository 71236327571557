import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.component.pagenation');
import { OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
    selector: 'wiz-component-component-pagenation',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/component.component.pagenation/view.scss */
.pagenation {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn {
  border-radius: 32px;
  width: 24px;
  height: 24px;
  margin: 0 3px;
  border: 0;
  background-color: inherit;
}
.btn.disabled {
  opacity: 0.2;
}
.btn:hover {
  background: rgba(176, 176, 232, 0.66);
  color: white;
}

.btn-blue {
  background: #B0B0E8;
}`],
})
export class ComponentComponentPagenationComponent implements OnInit, OnChanges {
    @Input() current: any = 1;
    @Input() start: any = 1;
    @Input() end: any = 1;
    @Output() pageMove = new EventEmitter<number>();

    public list: Array<number> = [];

    public async ngOnInit() {
        this.Math = Math;
    }

    public async ngOnChanges() {
        this.list = [];
        for (let i = 0; i < 10; i++) {
            if (this.start + i > this.end) break;
            this.list.push(this.start + i);
        }
    }

    public move(page: number) {
        if (page < 1) {
            toastr.error('첫 페이지 입니다');
            return;
        }
        if (page > this.end) {
            toastr.error('마지막 페이지 입니다');
            return;
        }
        if (this.current == page) {
            return;
        }
        this.pageMove.emit(page);
    }
}

export default ComponentComponentPagenationComponent;