import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.manage.dataset.drive');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

import { FlatTreeControl } from '@angular/cdk/tree';
import { FileNode, FileDataSource } from "src/libs/drive/file";



@Component({
    selector: 'wiz-component-manage-dataset-drive',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/component.manage.dataset.drive/view.scss */
.br-10 {
  border-radius: 10px;
}

.container-drive .navigation {
  margin-top: 10px;
  padding: 8px 16px;
  background: #B0B0E8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.container-drive table tr {
  background: white;
  border-style: solid;
}
.container-drive table tr td {
  padding: 10px 16px;
}
.container-drive table tr td .avatar-container {
  display: flex;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
}
.container-drive table tr td .avatar-container.folder {
  background-color: #B0B0E8;
  color: #EDEEF8;
}
.container-drive table tr td .avatar-container.file {
  background-color: #EDEEF8;
  color: #B0B0E8;
}
.container-drive table tr td .avatar-container i {
  width: 32px;
  align-items: center;
}
.container-drive table tr td:first-child {
  border-left: 1px solid #EDEEF8;
}
.container-drive table tr td:last-child {
  border-right: 1px solid #EDEEF8;
}`],
})
export class ComponentManageDatasetDriveComponent implements OnInit {
    @Input() datasetID: any;
    @Input() setBoard: any;

    private storage = null;
    private root = null;
    private base = null;
    private path = null;

    public rootNode: FileNode;
    private treeControl: FlatTreeControl<FileNode>;
    private dataSource: FileDataSource;
    private getLevel = (node: FileNode) => node.level;
    private isExpandable = (node: FileNode) => node.extended;

    private mkdir = false;
    private dirName = "";

    private selectAll = false;
    private isRenaming = {};

    constructor(@Inject( Service)         public service: Service,    ) {
        // 파일 버전 관리 관련
        this.rootNode = new FileNode('root', this.root, 'folder');
        this.treeControl = new FlatTreeControl<FileNode>(this.getLevel, this.isExpandable);
        this.dataSource = new FileDataSource(this);
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.render();
        this.rootNode = new FileNode('root', this.root, 'folder');
        this.treeControl = new FlatTreeControl<FileNode>(this.getLevel, this.isExpandable);
        this.dataSource = new FileDataSource(this);

        await this.init();
        await this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async init() {
        let { code, data } = await wiz.call("init", { id: this.datasetID, mode: this.setBoard });
        if (code !== 200) {
            alert("오류가 발생했습니다. 다시 시도해주세요.");
            location.reload();
        }
        this.root = data;
        this.base = data;

        let res = await this.list(this.rootNode);
        this.dataSource.data = res;

        await this.load();
    }

    public async load() {
        this.id = WizRoute.segment.id;
        let { code, data } = await wiz.call("load", { id: this.id, base: this.base })
        if (code == 200) {
            // this.files = data.files.filter(file => !(file.name === "cache" && file.type === "folder"));
            this.files = data.files;
            this.dirName = "";
            this.path = this.base.replace(this.root, '');
            this.mkdir = false;
            this.selectAll = false;

            await this.service.render();
        }
    }

    private async list(node: FileNode) {
        let { code, data } = await wiz.call("list", { path: this.base });
        data = data.map(item => new FileNode(item.name, item.path, item.type, node, node.level + 1));
        data.sort((a, b) => {
            if (a.type == b.type)
                return a.path.localeCompare(b.path);
            if (a.type == 'folder') return -1;
            if (b.type == 'folder') return 1;
        });
        return data;
    }

    private async move(node: FileNode) {
        if (node === undefined) {
            await this.init();
            return;
        }

        if (node.type === "folder") {
            this.base = node.path;
        }

        if (node.extended == true) {
            await this.dataSource.toggle(node, false);
            this.base = this.base.substring(0, this.base.lastIndexOf('/'));
            await this.load();
            return;
        }

        for (let i = 0; i < this.dataSource.data.length; i++) {
            let dataNode = this.dataSource.data[i];
            if (dataNode.extended === true && !node.path.includes(dataNode.path)) {
                await this.dataSource.toggle(dataNode, false);
            }
        }

        await this.dataSource.toggle(node, true);
        await this.load();
    }

    private async delete() {
        let alert = await this.service.alert.show({
            title: "",
            message: "정말 이 파일을 삭제하겠습니까?",
            cancel: "No",
        });

        if (!alert) {
            return;
        }

        for (let file of this.files) {
            if (file.checked) {
                let { code, data } = await wiz.call("delete", { base: this.base, file: JSON.stringify(file) });
                if (code !== 200) {
                    alert("remove 과정에서 오류가 발생했습니다.");
                    return;
                }
            }
        }
        let res = this.dataSource.data.find(item => item.path === this.base);
        await this.refresh(res);

        await this.load();
    }

    private async create() {
        if (!this.mkdir) {
            this.mkdir = true;
            setTimeout(async () => {
                const inputEl = document.querySelector("#mkdir");
                inputEl.focus();
            }, 300);
        }
        else {
            this.mkdir = false;

            if (this.dirName.length === 0) {
                return;
            }

            let { code, data } = await wiz.call("create", { base: this.base, dirName: this.dirName });
            if (code !== 200 && code !== 201) {
                alert("create 과정에서 오류가 발생했습니다.");
                return;
            }
            else if (code === 201) {
                this.service.toast.error("이미 존재하는 이름입니다.");
                return;
            }

            let res = this.dataSource.data.find(item => item.path === this.base);
            await this.refresh(res);

            await this.load();
        }
        await this.service.render();
    }

    private async upload(data = null) {
        await this.loading(true);
        let files = data;
        if (files == null) {
            files = await this.service.file.select({ multiple: true });
        }

        let fd = new FormData();
        let filepath = [];

        for (let i = 0; i < files.length; i++) {
            if (!files[i].filepath) files[i].filepath = this.base;
            else {
                let to = files[i].filepath.substring(0, files[i].filepath.lastIndexOf('/'));
                files[i].filepath = this.base + "/" + to;
            }
            fd.append('file[]', files[i]);
            filepath.push(files[i].filepath);
        }

        fd.append("filepath", JSON.stringify(filepath));

        let url = wiz.url('upload');
        await this.service.file.upload(url, fd);

        let res = this.dataSource.data.find(item => item.path === this.base);
        await this.refresh(res);

        await this.load();

        await this.loading(false);
    }

    private async drop($event) {
        $event.preventDefault();
        let files = await this.service.file.drop($event);
        await this.upload(files);
    }

    private async checkAll() {
        for (const file of this.files) {
            file.checked = this.selectAll;
        }
    }

    private async checked(file) {
        file.checked = !file.checked;

        this.selectAll = this.files.every(file => file.checked);
        await this.service.render();
    }

    private async goBack() {
        let oldPath = this.base;
        this.base = this.base.substring(0, this.base.lastIndexOf('/'));

        let res = this.dataSource.data.find(item => item.path === oldPath);
        await this.move(res);

        await this.load();
    }

    private async open(file) {
        let filePath = file.path.split(this.root);
        file.path = this.root + filePath.slice(1).join(this.root);

        let res = this.dataSource.data.find(item => item.name === file.name && item.path === file.path);
        if (res) {
            await this.move(res);
        }
    }

    private async rename(file, i) {
        if (!this.isRenaming[i]) {
            this.isRenaming[i] = true;
            setTimeout(async () => {
                const inputEl = document.querySelector("#rename");
                inputEl.focus();
            }, 300);
        }
        else {
            if (file.name.length === 0) {
                this.service.toast.error("이름은 비워둘 수 없습니다");
                return;
            }

            let filter = this.files.filter(item => item.name === file.name);

            this.isRenaming[i] = false;

            let { code, data } = await wiz.call("rename", { base: this.base, name: file.name, path: file.path });
            if (code !== 200 && code !== 201) {
                alert("rename 과정에서 오류가 발생했습니다.");
                return;
            }
            else if (code === 201) {
                this.service.toast.error("존재하지 않는 파일입니다.");
                return;
            }

            let filePath = file.path.split(this.root);
            file.path = this.root + filePath.slice(1).join(this.root);

            let res = this.dataSource.data.find(item => item.path === file.path);
            await this.refresh(res);

            await this.load();
        }
        await this.service.render();
    }

    public async download(file) {
        let download = wiz.url("download?path=" + this.base + "&name=" + file.name)
        window.open(download, '_blank');
    }

    public async refresh(node: FileNode | null = null) {
        if (node && node.parent) {
            await this.dataSource.toggle(node, false);
            await this.dataSource.toggle(node, true);
        } else {
            let data = await this.list(this.rootNode);
            this.dataSource.data = data;
        }
    }

    public filesize(value) {
        if (!value) return "--";
        let kb = value / 1024;
        if (kb < 1) return value + "B";
        let mb = kb / 1024;
        if (mb < 1) return Math.round(kb * 100) / 100 + "KB";
        let gb = mb / 1024;
        if (gb < 1) return Math.round(mb * 100) / 100 + "MB";
        return Math.round(gb * 100) / 100 + "GB";
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }
}

export default ComponentManageDatasetDriveComponent;