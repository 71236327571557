import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit, Input } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/page.main/view.scss */
.container-full {
  height: 100%;
  overflow: auto;
}
.container-full table {
  table-layout: fixed;
}
.container-full .download .btlr {
  border-top-left-radius: 10px;
}
.container-full .download .btrr {
  border-top-right-radius: 10px;
}
.container-full .download table {
  border-radius: 10px;
}
.container-full .download thead th {
  background-color: #F0F1F9;
  font-size: 14px;
  vertical-align: middle;
  color: #777986;
  border-top: 2px solid #D2D4DA;
}
.container-full .download tbody th:first-child,
.container-full .download tbody td:first-child {
  border-right: 1px solid #D2D4DA;
}
.container-full .download tbody th {
  font-size: 15px;
}
.container-full .download tbody td {
  align-content: center;
  font-size: 20px;
  font-weight: bold;
  background: white;
}
.container-full .share {
  justify-content: center;
}
@media (max-width: 750px) {
  .container-full .share {
    max-width: 800px;
    flex-direction: column;
    align-items: center;
  }
}
.container-full .share .card {
  cursor: pointer;
  width: 550px;
  height: 200px;
  border-radius: 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.container-full .share .card:nth-child(2) {
  margin: 0 60px;
}
@media (max-width: 750px) {
  .container-full .share .card {
    margin: 0;
  }
}
.container-full .meta .text {
  margin-bottom: 10px;
  align-items: center;
}
.container-full .meta .text span,
.container-full .meta .text i {
  color: red;
}
.container-full .meta .btn-open {
  width: 100%;
  height: 60px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
@media (max-width: 750px) {
  .container-full .meta .btn-open {
    width: 800px;
  }
}
.container-full .meta .open {
  border-radius: 15px 15px 0 0;
}
.container-full .meta .card {
  border-radius: 0 0 15px 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
@media (max-width: 750px) {
  .container-full .meta .card {
    width: 800px;
  }
}
.container-full .meta th {
  background: #EDEEF8;
}
.container-full .meta tr {
  border-right: 1px solid #E6E7E9;
}
.container-full .meta tr:first-child {
  border-top: 1px solid #E6E7E9;
}
.container-full .meta td {
  max-width: 530px;
}
.container-full .meta .year i {
  color: #4F40F1;
}
.container-full .meta .year .btn-raw {
  padding: 0;
  font-size: 12px;
  color: #4F40F1;
  border: none;
  box-shadow: none;
}
.container-full .meta .year .btn-raw:hover {
  text-decoration: underline;
}

.h1-red {
  color: red;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.h1-purple {
  color: purple;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.h1-black {
  color: black;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}`],
})
export class PageMainComponent implements OnInit {

    public share_ls = [
        { title: "미확인 공유 요청 건수", css: "h1-red", value: "request" },
        { title: "심사 진행 건수", css: "h1-purple", value: "process" },
        { title: "심사 완료 건수", css: "h1-black", value: "allow" },
    ]

    public metadata: any;
    public schema: any;
    public dataRow: any;
    public info: any;
    public none_file = [];
    public deviceCount: any;

    constructor(@Inject( Service)         public service: Service,@Inject( Router    )         private router: Router    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/authenticate');
        await this.load();
        await this.meta();
    }

    public async load() {
        let { code, data } = await wiz.call("load");
        this.role = data.role;

        this.share_ls[0].count = data.request;
        this.share_ls[1].count = data.process;
        this.share_ls[2].count = data.finish;

        await this.service.render();
    }

    public click(category) {
        this.service.href(`/share/list/` + category);
    }

    public async meta() {
        const { code, data } = await wiz.call("meta");
        this.metadata = data;
        await this.service.render();
    }

    public async open(index) {
        this.tab = this.tab === index ? null : index;
        const { code, data } = await wiz.call("info", { datacode: this.metadata[index].datacode });
        this.schema = data.schema;
        this.info = data.data;
        this.info = this.info.filter(item => item.num !== 0);
        this.none_file = data.none;
        this.deviceCount = data.count;
        await this.file_load(this.metadata[index].datacode)
        await this.service.render();
    }

    public async alert(message: string, status: string = 'success', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async download(datacode, category, year) {
        let res = await this.alert("파일 저장 화면이 뜰때까지 잠시 기다려주십시오.", "success");
        if (res) {
            let download = wiz.url('download?datacode=' + datacode + "&category=" + category + "&year=" + year)
            window.location.href = download;
        } else return;

    }

    public async file_load(datacode) {
        const { code, data } = await wiz.call("device_info", { datacode: datacode });
        this.dataRow = data.sum_info
        await this.service.render();
    }

    public isShow: boolean = false;
    public modalData: any;
    public modalYear: any;
    public async modalOpen(datacode: any, year: any) {
        this.isShow = true;
        this.modalData = datacode;
        this.modalYear = year
        await this.service.render();
    }

    public async modalHide(tf) {
        this.isShow = tf;
        this.modalData = '';
        this.modalYear = '';
        await this.service.render();
    }
}

export default PageMainComponent;