import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.manage.dataset.share.raw');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-manage-dataset-share-raw',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/page.manage.dataset.share.raw/view.scss */
.overflow-x {
  overflow: auto;
}

.btlr {
  border-top-left-radius: 10px;
}

.btrr {
  border-top-right-radius: 10px;
}

.br-10 {
  border-radius: 10px;
}

.box-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.form-select {
  border: 1px solid #C9C5F0;
  border-radius: 20px;
}

.btn-process {
  background-color: #C9C5F0;
  color: black;
  border-radius: 10px;
  border: 1px solid #C9C5F0;
  font-weight: bold;
}

.board-table {
  background: white;
}
.board-table td,
.board-table th {
  white-space: nowrap;
  text-align: left;
}
.board-table thead th {
  background-color: #B9B9DD;
  color: black;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 0;
}`],
})
export class PageManageDatasetShareRawComponent implements OnInit {

    public page = {
        start: 1,
        end: 1,
        current: 1,
    };

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/authenticate');
        await this.load();
        await this.pageLoad(1);
        await this.service.render();
    }

    public async load() {
        let { code, data } = await wiz.call("load", { page: this.page.current });
        this.list = data.rows;
        this.lastpage = data.lastpage;
        this.page.start = (parseInt((this.page.current - 1) / 10) * 10) + 1;
        this.page.end = this.lastpage;
        await this.service.render();
    }
    private pageLoad(p: number) {
        this.page.current = p;
        this.load();
    }

    public async download(id:any) {
        let download = wiz.url("downZip?id=" + id)
        window.location.href = download;
    }
}

export default PageManageDatasetShareRawComponent;