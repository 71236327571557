import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.component.card.notification');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-component-card-notification',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/component.component.card.notification/view.scss */
.dropdown-menu {
  top: 56px;
  transform: translate(-490px, 0%);
  width: 500px;
}
@media (max-width: 575px) {
  .dropdown-menu {
    width: 100%;
    transform: none;
    left: 0px;
  }
}
.dropdown-menu .list-group {
  height: 300px;
  overflow-y: auto;
}
.dropdown-menu .list-group .list-group-item {
  cursor: pointer;
}
.dropdown-menu .background-muted {
  background-color: #E8E8F0;
}

.btn-request {
  background-color: #E2E4F0;
  color: var(--wiz-color-navy);
  border-radius: 10px;
  border: 1px solid #E2E4F0;
  font-weight: bold;
  pointer-events: none;
}

.btn-allow {
  background-color: #64CF7C;
  color: white;
  border-radius: 10px;
  border: 1px solid #64CF7C;
  font-weight: bold;
  pointer-events: none;
}

.btn-reject {
  background-color: #F0142F;
  color: white;
  border-radius: 10px;
  border: 1px solid #F0142F;
  font-weight: bold;
  pointer-events: none;
}

.btn-process {
  background-color: #C9C5F0;
  color: black;
  border-radius: 10px;
  border: 1px solid #C9C5F0;
  font-weight: bold;
  pointer-events: none;
}

.btn-refresh {
  border: 0px !important;
  cursor: pointer;
}
.btn-refresh i {
  font-size: 20px;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotate-animation {
  animation: rotateAnimation 0.4s linear;
}`],
})
export class ComponentComponentCardNotificationComponent implements OnInit {
    private list = [];
    private isAnimating = false;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.load();
    }

    private async load() {
        const { code, data } = await wiz.call("load");
        if (code !== 200) {
            alert("[ERROR] load list");
            return;
        }
        this.list = data;
        await this.service.render();
    }

    private async show(item) {
        if (item.show !== 1) {
            const { code } = await wiz.call("update", { id: item.id });
            if (code !== 200) {
                alert("[ERROR] update show");
                return;
            }
        }

        if (item.title == "전문가 심사 요청") {
            this.service.href("share/expert/form/view/" + item.id);
        }
        else if (item.title == "데이터셋 공유 요청") {
            this.service.href("/share/user/form/view/" + item.id);
        }
        await this.load();
    }

    private async refresh() {
        this.isAnimating = true;

        setTimeout(async () => {
            this.isAnimating = false;
        }, 400);

        await this.load();
    }
}

export default ComponentComponentCardNotificationComponent;